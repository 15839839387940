@import '../../variables.scss';

.visuPlaceholder {
  position: absolute;
  top: 0;
  left: 3.35rem;
  width: calc(100% -3rem);
  height: 100%;
  display: flex;
  flex-direction: row;
  z-index: 80;

  &__layerstree {
    width: 19.5rem;
    height: 100%;
    background-color:#30404d;
    box-shadow: 9px 8px 24px 0 rgba(16,22,26,0.40);
    padding: .3rem 0 .5rem .8rem;

    &-appName, &-title {
      width: calc(100% - 8rem);
      height: 1.8rem;
      margin-bottom: 1.5rem;
      padding: .3rem 0 .5rem .8rem;
    }

    &-appName {
      width: calc(100% - 2rem);
      height: 2.2rem;
      margin-bottom: .5rem;
    }

    &-group {
      margin-top: 1.5rem;
    }

    &-group-title {
      width: calc(100% - 6rem);
      height: 1.4rem;
    }
    &-group-items {
      width: calc(100% - 1.5rem);
      height: auto;
      padding: 0 1.5rem 0 1.2rem;
    }
    &-group-item {
      list-style: none;
      width: 100%;
      height: 2rem;
      border: 1px solid #30404D;
    }
  }
}
.skeleton {
  background: linear-gradient(to left, #30404d, #394b59);
  background-size: 200%;
  animation: 1s linear 0s alternate infinite placeholderShimmer;
}

@keyframes placeholderShimmer {
  from { background-position: 0 0; }
  to { background-position: 100% 0; }
}

@media(min-width: $breakpoints-tablet) and (max-width: $breakpoints-desktop) and (orientation: landscape) {
  .visuPlaceholder {
    left: 2.95rem;
    &__layerstree {
      width: 17.5rem;
    }
  }
}
@media(min-width: $breakpoints-phone) and (max-width: $breakpoints-tablet) and (orientation: portrait) {
  .visuPlaceholder {
    left: 2.85rem;
    &__layerstree {
      width: 14rem;
    }
  }
}
